import React from 'react'
import { Configure } from 'react-instantsearch-dom'
import { connectStateResults, StateResultsProvided } from 'react-instantsearch/connectors'
import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { ICompositeOpportunity } from '../../models/algolia/composite'
import { Stringable } from '../../util'
import { Colspec, parseColumnClasses } from '../../util/columns'
import AlgoliaSearch from '../algolia-search'
import HiddenFacets from '../SiteSearch/hidden-facets'
import ConnectedOpportunityHitTableView, { OpportunityHitLoadingView } from '../SiteSearch/hits/opportunity-table'
import HiddenNumericFilters from '../SiteSearch/numeric-filters'

interface IServeGalleryProps extends ITranslationsProvided {
  source: IAlgoliaServeSource

  header?: string | boolean
  viewAllLink?: string

  /**
   * Specify the number of columns at each breakpoint.  This is turned into
   * bootstrap column specifiers by taking 12 / #columns.
   *
   * Examples:
   * 2 => col-6
   * { xs: 3, md: 2 } => col-4 col-md-6
   * { xs: 10.0/12.0, xl: 3 } => col-10 col-xl-4
   */
  columns?: number | { xs: number, [key: string]: number  }

  hitsPerPage?: number
}

interface IAlgoliaServeSource {
  source?: 'Algolia' | undefined,
  index: string
  facetFilters?: {
    [key: string]: Stringable[] | Stringable,
  }
  operator?: 'or' | 'and'
  hiddenNumericFilters?: string[]
}

const DefaultNumericFilters: string[] = [
  'Opportunity.expiration_date_timestamp>${now}',
  'Opportunity.activated_date_timestamp<${now}'
]

export function ServeGallery(props: IServeGalleryProps) {
  const {source, header, viewAllLink, hitsPerPage, t} = props

  const hiddenNumericFilters = source.hiddenNumericFilters ||
    DefaultNumericFilters

  return <AlgoliaSearch indexName={source.index} widgetName="blog-gallery">
    <Configure
      analytics={false}
      hitsPerPage={hitsPerPage || 3} />

    <HiddenFacets hiddenFacets={{ object_type: 'Opportunity' }} />
    <HiddenNumericFilters hiddenNumericFilters={hiddenNumericFilters} />
    {source.facetFilters &&
        <HiddenFacets hiddenFacets={source.facetFilters}
          operator={source.operator} />}

    <div className="row justify-content-center">
      <div className="col-content-container">
        <div className="row">
          <div className="col-12">
            <div className="widget-serve-gallery__header">
              {header !== false &&
                  <h2>{typeof header == 'string' ? header : t('header')}</h2>}
              {viewAllLink &&
                  <a href={viewAllLink} className="btn btn-link">View All</a>}
            </div>

            <ConnectedServeGalleryBlockLayout {...props} />
          </div>
        </div>
      </div>
    </div>
  </AlgoliaSearch>
}

const ConnectedServeGallery = withTranslations(ServeGallery)
export default ConnectedServeGallery

type BlockLayoutProps = IServeGalleryProps & StateResultsProvided<ICompositeOpportunity>

function ServeGalleryBlockLayout(props: BlockLayoutProps) {
  const {searchResults, searching, hitsPerPage} = props

  const columns: Colspec =
    typeof props.columns == 'number' ?
      { xs: 1, md: props.columns } :
      {
        xs: 1,
        md: 3,
        ...props.columns,
      }

  const colClasses = parseColumnClasses(columns)

  if (!searchResults || searching) {
    return <div className="row hit-list widget-serve-gallery__hit-list">
      {Array.from({length: hitsPerPage || 3}, (_, i) => {
        return <div className={`widget-serve-gallery__hit ${colClasses.join(' ')}`}
          key={i}>
          <OpportunityHitLoadingView layout="block" />
        </div>
      })}
    </div>
  }

  if (searchResults.nbHits == 0) {
    return <div className="row hit-list widget-serve-gallery__hit-list">
      <div className="blank-slate justify-content-center d-flex flex-column text-center alert-light">
        There are no service opportunities available at this time.
      </div>
    </div>
  }

  return <div className="row hit-list widget-serve-gallery__hit-list">
    {searchResults.hits.map((hit) => {
      return <div className={`widget-serve-gallery__hit ${colClasses.join(' ')}`}
        key={`serve-gallery-hit-${hit.objectID}`}>
        <ConnectedOpportunityHitTableView
          hit={hit}
          layout="block"
        />
      </div>
    })}
  </div>
}

const ConnectedServeGalleryBlockLayout =
  connectStateResults(ServeGalleryBlockLayout)
